<script lang="ts">
export let text: string
</script>

{#if text}
  <h5 class="section-title">
    {text}
  </h5>
{/if}

<style lang="scss">
h5 {
  font-size: 21px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.32px;
  margin: 0;
}
</style>
